@media only screen and (max-width: 1000px){
    .navbar-bottom {
        display: none !important;
    }
    .priceOnBarContainer{
        display: block !important;
    }
    .logoOnNav{
        width: 65vw;
    }
    .logoOnNav img{
        width: 30vw;
        height: 30vw;
        margin-top: 13%;
    }
    .priceOnBarContainer{
        margin-left: 15%;
        margin-right: 15%;
    }
    .priceOnNavBar-price{
        font-size: 5vw;
        margin-left: 10%;
    }
    .priceOnNavBar-tag{
        align-items: center;
        font-size: 5vw;
    }
    .menu{
      z-index: 9999;
    }
}
@media only screen and (min-width: 1001px){
    .menu{
        display: none;
    }
}

/* Icon 1 */
.animated-icon1 {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.animated-icon1 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.animated-icon1 span {
  background: #f3e5f5;
}

.animated-icon1 span:nth-child(1) {
  top: 0px;
}

.animated-icon1 span:nth-child(2) {
  top: 10px;
}

.animated-icon1 span:nth-child(3) {
  top: 20px;
}

.animated-icon1.open span:nth-child(1) {
  top: 11px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.animated-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.animated-icon1.open span:nth-child(3) {
  top: 11px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.bg-light{
    display: flex;
    flex-direction: column;
    background-color: black;
}

.menu {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0.95;
}

.menu nav{
    background-color: black;
}

.ripple {
    background-color: black !important;
}

.p-4{
    margin: -1.5rem;
}

.border-bottom{
    font-size: 3vh !important;
}