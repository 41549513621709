.adminNavBar{
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 1%;
    border-radius: 10px;
    position: sticky;
    top: 1%;
}

.adminNavTag{
    color: white !important;
    font-size: larger;
}

.adminNavTag:hover{
    color:rgb(0, 55, 255) !important;
}