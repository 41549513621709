@media only screen and (max-width: 1000px){
    .recharts-responsive-container{
        width: 100% !important;
        height: 30vh !important;
    }
    .priceDisplay-label{
        display: none;
    }
    .price-selector select {
        display: none;
    }
    .date-selector p {
        display: none;
    }

    .date-selector{
        flex-direction: column;
    }
    .price-date-selector-from-PNT{
        display: flex;
        flex-direction: row;
        width: 60%;
        margin: auto;
        justify-content: space-evenly;
        border-bottom: 2px solid rgb(32, 74, 244);
    }
    .price-date-selector-to-PNT{
        display: flex;
        flex-direction: row;
        width: 60%;
        margin: auto;
        justify-content: space-evenly;
        border-bottom: 2px solid rgb(32, 74, 244);
    }
    .rdrMonth{
        margin: auto;
    }
}