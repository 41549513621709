.adminCheckVideo-row{
    display: flex;
    flex-direction: row;
    padding: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    border: 1px solid;
    margin: 1%;
    margin-right: 15%;
    margin-left: 15%;
}

.adminCheckVideo-row-left{
    flex: 1;
}

.adminCheckVideo-row-right{
    flex: 4;
}

.uploadVideo{
    border: 1px solid;
    margin-right: 15%;
    margin-left: 15%;
    font-size: xx-large;
    margin-top: 1%;
    cursor: pointer;
}

.uploadVideo:hover{
    background-color: grey;
}

.adminCheckVideo-row:hover{
    background-color: rgba(89, 89, 89, 0.154);
    cursor: pointer;
}