@media only screen and (max-width: 1000px){
    .contactUs-contentContainer{
        display: flex;
        flex-direction: column;
    }
    .contactUs-info{
        padding-right: 0;
        margin: auto;
        width: 100% !important;
        padding: 10%;
        text-align: center;
    }
    .contactUs-contentContainer{
        margin-left: 0%;
        margin-right: 0%;
        align-items:center;
    }
    .map-container{
        width: 80vw;
        height: 40vh;
    }
    Form{
        padding-left: 5%;
        padding-right: 5%;
    }
}