.main{
    height: 50vw;
}

.main-contentContainer{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: 100%;
}

.main-content-transbox{
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.main-contentContainer-text{
    color: rgba(255,255,255,1);
    padding-top: 5%;
    padding-right: 20%;
    padding-left: 20%;
    font-size: 3.4vw;
}

.main-contentContainer-text p{
    font-size: 60%;
}

.main-content-transbox h1{
    padding-top: 5%;
    background: rgba(0, 0, 0, 0);
}

.main-content-transbox-button{
    display: inline-block;
    color: white;
    background-color: rgb(212, 151, 9);
    padding: 0.4%;
    border-radius: 5px;
    transition: all .5s ease-in;
    font-size: 1.5vw;
}

.main-content-transbox-button:hover{
    background-color: rgba(255,255,255,0.95);
    color: rgba(212, 151, 9,1);
    cursor:pointer;
    size: 110%;
    transform: scale(1.05);
}