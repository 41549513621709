.contactUs-contentContainer *{
    display: inline-block;
    padding-right: 10%;
}

.contactUs-info{
    padding-top: 5%;
    width: 45%;
    text-align: start;
}
.contactUs-info *{
    display: block;
}

.contactUs-info-facebook a, .contactUs-info-whatsapp a{
    display: inline-block;
}


Form{
    padding-top: 5%;
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 2%;
    text-align: start;
}

Form h2{
    text-align: center;
}

.buttonContainer{
    text-align: center;
}

.spinnerContainer{
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    top: 0%;
    left: 0%;
}

#root div div.contactUs form div.buttonContainer button{
    background-color: rgb(255, 221, 142);
    border: 1px rgba(212, 151, 9, 1);
}

#root div div.contactUs form div.buttonContainer button:hover{
    background-color: rgba(212, 151, 9, 1)!important;
}


.map-container{
    width: 30vw;
    height: 20vw;
}

.contactUs-contentContainer{
    margin-left: 15%;
    display: flex;
    vertical-align: top;
    flex-direction: row;
    justify-content: center;
    margin-right: 15%;
}