.aboutUs{
    height: 100%;
}

.aboutUs-content-transbox{
    height: 50vh;
    background-repeat: no-repeat;
    background-position: top;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 2%;
}

.aboutUs-contentContainer-text{
    background: rgba(255, 255, 255, 0.7);
    height: 50vh;
    font-size: 1.5vw;
    color: rgba(0s,0,0,1);
    padding-top: 5%;
    padding-right: 20%;
    padding-left: 20%;
}

.aboutUs-content-marketInfo{
    padding-top: 2%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: aliceblue;
}

.aboutUs-content-marketInfo-content{
    vertical-align: middle;
    display: inline-block;
    width: 40%;
}

.aboutUs-content-marketInfo-image{
    display: inline-block;
    padding-bottom: 1%;
    padding-left: 2%;
}

.aboutUs-content-marketInfo-content div{
    display: inline-block;
    border: 2px solid;
    margin: 1%;
    padding: 1.3%;
    border-radius: 30px;
    cursor: pointer;
    color: rgba(212, 151, 9, 1);
}

.aboutUs-content-marketInfo-content div:hover{
    background-color: rgba(212, 151, 9, 1);
    color: white;
}

.aboutUs-content-licenseAgent{
    padding-top: 2%;
    padding-left: 5%;
    padding-right: 5%;
}

.aboutUs-content-licenseAgent-content{
    vertical-align: middle;
    display: inline-block;
    width: 40%;
}

.aboutUs-content-licenseAgent-image{
    display: inline-block;
    padding-bottom: 1%;
    padding-left: 2%;
}

.aboutUs-content-licenseAgent-content div{
    display: inline-block;
    border: 2px solid;
    margin: 1%;
    padding: 1.3%;
    border-radius: 30px;
    cursor: pointer;
    color: rgba(212, 151, 9, 1);
}

.aboutUs-content-licenseAgent-content div:hover{
    background-color: rgba(212, 151, 9, 1);
    color: white;
}

.aboutUs-content-management{
    padding-top: 2%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: aliceblue;
}

.aboutUs-content-management-content{
    vertical-align: middle;
    display: inline-block;
    width: 40%;
}

.aboutUs-content-management-image{
    display: inline-block;
    padding-bottom: 1%;
    padding-left: 2%;
}

.aboutUs-content-management-content div{
    display: inline-block;
    border: 2px solid;
    margin: 1%;
    padding: 1.3%;
    border-radius: 30px;
    cursor: pointer;
    color: rgba(212, 151, 9, 1);
}

.aboutUs-content-management-content div:hover{
    background-color: rgba(212, 151, 9, 1);
    color: white;
}

.card-group{
    padding-left: 15%;
    padding-right: 15%;
}

.card{
    border: 1px solid rgb(69, 69, 69) !important;
    border-radius: 8px !important;
}

.card-container{
    margin-left: 5%;
    margin-right: 5%;
    display: grid !important;
}

.aboutUs-selectedServiceInfo{
    padding: 20%;
    padding-top: 1%;
    padding-bottom:2%;
    background-color: rgb(202, 221, 255);
    animation: fadeInAnimation ease 0.5s;
    margin-right: 10%;
    margin-left: 10%;
    border-radius: 20px;
}

.aboutUs-selectedServiceInfo-content{
    padding-top: 2%;
}

.aboutUs-selectedService-arrow-up {
    width: 0; 
    height: 0; 
    border-left: 2vw solid transparent;
    border-right: 2vw solid transparent;
    border-bottom: 2vw solid rgb(202, 221, 255);
    margin: auto;
    margin-top: 2%;
    animation: fadeInAnimation ease 0.5s;
  }

  @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}
.aboutUs-selectedService-choice{
    display: inline-flex;
    flex-direction: column;

}

.aboutUs-selectedService-choice a{
    text-decoration: none;
}

.aboutUs-selectedService-choice a div{
    background-color: rgba(212, 151, 9, 1);
    color: white;
    margin: 3px;
    border-radius: 5px;
    padding: 3px;
    font-size: 1vw;
}

.aboutUs-selectedService-choice a div:hover{
    background-color: white;
    color: rgba(212, 151, 9, 1);
}

.aboutUs-videoContainer {
    background-color: rgba(134, 130, 130, 0.297);
    padding-top: 1%;
    padding-bottom: 1%;
    margin-bottom: 2%;
}

.aboutUs-videoContainer *{
    margin: auto;
}
