@media only screen and (max-width: 1000px){
    .player{
        margin: auto;
    }
    .channel-content-video{
        flex-direction: column;
    }
    .channel-content-tag{
        width: 20%;
    }
}