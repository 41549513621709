.list-group{
    align-items: center;
}

.list-group *{
    width: 80%;
}

.contractDay *{
    display: inline-block;
    width: 45%;
}

.licenceType *{
    display: inline-block;
    width: 45%;
}

.carType *{
    display: inline-block;
    width: 45%;
}

.deposite *{
    display: inline-block;
    width: 45%;
}

.trading-price *{
    display: inline-block;
    width: 45%;
}

.tradingDay *{
    display: inline-block;
    width: 45%;

}
.recordList-content{
    text-align: start;
    font-size:larger;
}

.recordList-label{
    text-align: end;
    font-size:larger;
}

.recordRowImage{
    width: 20%;
    height: 20%;
}