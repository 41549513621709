nav{
    border-bottom: 1px solid rgb(106, 106, 106);
}

.navTop{
    text-align: initial;
}

.container{
    max-width: 80vw !important;
}

.logoOnNav{
    display: inline-block;
    width: 55vw;
    text-align: end;
}

.priceOnBarContainer{
    display: inline-block;
    vertical-align: bottom;
}

.logoOnNav img{
    width: 10vw;
    height: 10vw;
}

.navbar-nav{
    width: 100%;
    justify-content: space-evenly;

}

.navbar-nav a{
    padding-left: 5%;
    padding-right: 5%;
    font-size: large;
}

.theNavLink:hover{
    color: rgb(212, 151, 9) !important;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
}

.nav-icon{
    padding-left: 1% !important;
    padding-right: 1% !important;

}

.nav-icon *{
    opacity: 0.85;
}
.nav-icon *:hover{
    opacity: 1;
}

.navBarPrice{
    border-style: solid;
    border-width: 3px;
    vertical-align:middle;
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;
    transition: all .5s ease-in;
}

.navBarPrice p{
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 4px;
    margin-right: 4px;
}

.navBarPriceClaim{
    font-size: 70%;
    text-align: end;
}
/*
.greenTag:hover{
    background-color: green;
    color: white;
}

.redTag:hover{
    background-color: red;
    color: white;
}


.navBarPrice:hover p{
    color: white;
}
*/
.dropdown-item:active{
    background-color: rgb(212, 151, 9) !important;
}

.dropdown-menu{
    width:200%;
    top: initial !important;
    padding-top: 0% !important;
}


/*
    for nav bar 1
*/
.priceOnNavBar-area{
    margin: 0.5vh;
    background-color: green;
    color: rgb(245, 245, 220);
    padding: 0.4vh;border-radius: 0.5vh;
}

.priceOnNavBar-price{
    font-size: 1.3vw;
    display: flex;
}

.navBarPrice{
    border-color: black !important;
}
/*
.priceOnNavBar-tag{
    transform: perspective(100px) rotateX(25deg);
}*/