.channelNews-videoContainer{
    display: inline-flex ;
}

.channelNews-titleContainer{
    margin-top: 1%;
    margin-bottom: 2%;
}

.channelNews-contentContainer{
    text-align: start;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.channelNews{
    background-color: rgba(188, 188, 188, 0.219);
    margin-left: 18%;
    margin-right: 18%;
    border-radius: 1vw;
    padding: 1%;
}
.channelNews-videoContainer{
    height: 90% !important;
    width: 90% !important;
    aspect-ratio: 16/9;
}