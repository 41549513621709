@media only screen and (max-width: 1000px){
    .me-auto{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .list-group-item{
        width: 100%;
    }

    .me-auto *{
        width: auto;
        display: flex;
    }
}
