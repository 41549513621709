.admin-mainPage{
 height: 10vw;
 vertical-align: middle;
 align-items: center;
}

.main-button{
    display: inline-block;
    padding: 2%;;
    padding-left:10%;
    padding-right: 10%;
    width: 30%;
    margin: 1%;
    border: 3px solid;
}

.admin-mainPage:hover {
    cursor: pointer;
}

.main-button:hover{
    background-color: rgba(158, 158, 158, 0.887);
}