.App {
  text-align: center;
}

html, body{
  width: 100%;
  height: 100%;
}

h1{
  padding-top: 1%;
  color:  rgba(212, 151, 9, 1) !important;
}

.title-hr{
  margin-left: 30%;
  margin-right: 30%;
  color: rgb(2, 17, 154);
  background-color: rgb(2, 17, 154);
  height: 3px;
  border-radius: 5px;
  margin-bottom: 2%;

}

.knowledgeHr{
  margin-left: 0%;
  margin-right: 0%;
}


.channelNews-videoContainer img {
  object-fit: scale-down;
  max-width: 40%;
  height: auto;
}

