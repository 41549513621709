.row{
    padding-left: 2%;
    padding-right: 2%;
}

.col{
    padding-bottom: 2%;
}

.card{
    cursor: pointer;
}

.modal-dialog{
    min-width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-title{
    width: 100%;
    text-align: center;
}

.recharts-responsive-container{
    margin: auto;
    height: 20vw !important;
}

.rdrCalendarWrapper{
    transform: scale(0.85);
}

.date-selector{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.date-selector{
    padding-top: 1%;
}

.date-selector p {
    display: inline;
}

.price-date-selector-from-PNT{
    display: none;
}

.price-date-selector-to-PNT{
    display: none;
}
