.singleAdminTaxiRow{
    border: 1px solid;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 0.5%;
    border-radius: 5px;
}

.adminCheckTaxi-container-add{
    border: 1px solid;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 0.5%;
    font-size:4vw;
    cursor: pointer;
    border-radius: 5px;
}

.adminCheckTaxi-container-add:hover{
    background-color: rgba(153, 153, 153, 0.28);
}

.adminCheckTaxi-ModifyButton{
    background-color: darkgrey;
    display: inline;
    padding: 0.2%;
    cursor: pointer;
}

.adminCheckTaxi-ModifyButton:hover{
    background-color: grey;
}