.single-adminCheckRecord{
    border: 1px solid;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 0.5%;
    border-radius: 5px;
}

.adminCheckRecord-container-add{
    border: 1px solid;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 0.5%;
    font-size:4vw;
    cursor: pointer;
    border-radius: 5px;
}

.adminCheckRecord-container-add:hover{
    background-color: rgba(153, 153, 153, 0.28);
}

.adminInasertRecordContainer form label{
    display: block;
    padding: 1%;
}

.adminCheckRecod-ModifyButton{
    background-color: darkgrey;
    display: inline;
    padding: 0.2%;
    cursor: pointer;
}

.adminCheckRecod-ModifyButton:hover{
    background-color: grey;
}