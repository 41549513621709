th, td {
    border-bottom: 1px solid;
}

td{
    width: 20%;
}

table{
    display: inline-block;
}

.adminCheckPrice-container-add{
    border: 1px solid;
    border-radius: 2vw;
    width: 50%;
    margin: auto;
    margin-top: 1%;
    margin-bottom: 1%;
    cursor: pointer;
}

.adminCheckPrice-container-add:hover{
    background-color: rgba(78, 78, 78, 0.204);
}