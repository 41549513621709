@media only screen and (max-width: 1000px){
    .aboutUs-contentContainer-text{
        font-size: 2.8vw;
        padding-left: 10%;
        padding-right: 10%;
    }
    .aboutUs-content-transbox{
        background-size: 75vw !important;
    }

    .aboutUs-videoContainer div{
        width: 99% !important;
        height: min-content !important;
        margin: 0;
    }
    .card-container .card{
        display: flex;
        flex-direction: row;
    }
    .card-img-top{
        width: 20vw !important;
        height: 20vw !important;
    }
    .card-group{
        padding: 0;
    }
    .aboutUs-selectedService-choice a div {
        font-size: 5vw;
    }
    .aboutUs-selectedServiceInfo{
        padding-left: 10%;
        padding-right: 10%;
    }
}