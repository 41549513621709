.footer{
    background-color: #666666;

}
.footer hr{
    color: rgb(9, 43, 175);
    height: 3px;
    background-color: rgb(9, 43, 175);
    
}
.footer-column{
    display: inline-block;
    padding-left: 5%;
    padding-right: 5%;
    height: 15vh;
    vertical-align: bottom;
}

.footer-column ul{
    list-style-type: none;
}

.footer-column ul li{
    padding-bottom: 15%;
    display: flex;
}

.footer-column ul li a {
    text-decoration: none;
    color: white;
}

.footer-column ul li a:hover {
    color: rgba(212, 151, 9,1);
    transition-duration: 500ms;
}

.copyrightClaim{
    background-color: black;
    padding: 2px;
}
.copyrightClaim p{
    color:darkgrey;
}