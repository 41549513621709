.questionAnswer{
    text-align: start;
    padding-left: 10%;
    padding-right: 10%;
}

.questionAnswerBox h1{
    background: rgb(9,9,121);
    background: linear-gradient(90deg, rgba(9,9,121,1) 35%, rgba(0,212,255,0.2553396358543417) 100%);
    padding: 0.5%;
    padding-left: 2%;
}
.questionAnswerBox{
    margin: 1%;
    padding: 2%;
    border: 1px solid;
}
