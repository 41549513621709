.leaseRows{
    height: 100%;
}
.leaseRows *{
    display: inline-block;
    width: 16.6%;
    height: 100%;
    
}

.leaseRows * *{
    display: block;
    width: 100%;
    text-align: center;
}

.taxiTitle{
    width: 100%;
    padding-top: 2%;
    padding-bottom: 4%;
}

.taxiTitle *{
    width: 100%;
    display: block;
    text-align: center;
}


.leaseRowImage{
    width: 20%;
    height: 20%;
}

.lease-whatsappIcon{
    display: inline-block;
}
