.channel-content-tagContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.channel-content-tag{
    padding: 0.5%;
    padding-top: 0.2%;
    padding-bottom: 0.2%;
    margin-left: 0.2%;
    margin-right: 0.2%;
    width: 5%;
    background-color: rgba(212, 151, 9, 1);
    cursor: pointer;
    border-radius: 20px;
}

.channel-content-tag:hover{
    background-color: rgb(255, 179, 2);
}

.channel-content-video{
    display: flex;
    flex-direction: row;
    margin-left: 20%;
    margin-right: 20%;
    border: 1px solid;
    margin-top: 1%;
    padding: 1.5%;
    border-radius: 1vw;
    cursor: pointer;
}

.channel-content-video-left{
    flex:3;
    height: auto;
    width: auto;
    align-content: center;
    justify-content: center;
    display: inline-table;
}


.channel-content-video-left img {
    object-fit: scale-down;
    max-width: 80%;
    height: auto;
}

.channel-content-video-right{
    flex:4;
}

.player{
    height: 90% !important;
    width: 90% !important;
    aspect-ratio: 16/9;
}

.channel-content-video:hover{
    background-color: rgba(101, 101, 101, 0.236);
}

.adminModifyVideo{
    display: flex;
    flex-direction: column;
}

.adminModifyChannel-videoPlayer{
    margin: auto !important;
}

.adminModifyVideo-content{
    margin: auto;
    margin-top: 1%;
    margin-bottom: 1%;
}

.adminModifyVideo button{
    width: 10%;
    margin:auto;
    margin-bottom: 1%;
}
