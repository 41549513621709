@media only screen and (max-width: 1000px){
    .main-contentContainer-text p{
        font-size: 80%;
    }
    .main-content-transbox-button{
        font-size: 4vw;
    }
    .main-contentContainer{
        height: 50vh;
    }
    .main{
        margin-bottom: 50%;
    }
}
@media only screen and (min-width: 1001px){
    
}