@media only screen and (max-width: 1000px){
    .footer-column ul{
        height: 20vh;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
    }

    .footer-column{
        width: 80vw;
        height: 5vh;
        padding-left: 0%;
        padding-right: 0%;
    }
    .footer-column ul{
        height: auto;
    }
    
}
@media only screen and (min-width: 1001px){
    
}